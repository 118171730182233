import styled, { css } from 'styled-components';

import { A4_WIDTH, A4_HEIGHT } from '/imports/pdf/core/api/constants';

export const Page = styled.section`
  position: relative;
  width: ${A4_WIDTH}px;
  min-height: ${A4_HEIGHT}px;
  height: ${(p) => p.height || 'inherit'};
  ${(p) =>
    p.scale &&
    css`
      transform-origin: top left;
      transform: scale(${(p) => p.scale});
    `}

  ${({ width }) =>
    width &&
    css`
      width: ${({ width }) => width}px;
    `}

  ${({ dragEnable }) =>
    dragEnable &&
    css`
      min-height: ${({ theme: { scale } }) => A4_HEIGHT * scale}px;
    `}
    ${(p) => console.log(p)}
    ${({ $isSa, saScale }) =>
    $isSa &&
    css`
      min-height: ${A4_HEIGHT * saScale}px;
    `}
  @media print {
    min-height: auto;
  }
`;

export default Page;
